import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { FaUsers, FaBriefcase, FaMusic, FaBook, FaHome, FaHouseUser } from 'react-icons/fa';
import { MdFactory } from 'react-icons/md';

const GraphComponent = ({ data, title, showGraph, toggleGraph, topic, rememberGlobal, forgetGlobal }) => {
  const [rememberedLines, setRememberedLines] = useState([]);

  useEffect(() => {
    if (rememberGlobal) {
      rememberLine();
    }
  }, [rememberGlobal]);

  useEffect(() => {
    if (forgetGlobal) {
      forgetLines();
    }
  }, [forgetGlobal]);

  
const rememberLine = () => {
  setRememberedLines((prev) => [
    ...prev,
    ...data.datasets
      .filter((dataset) => dataset.hidden !== true) // Only remember visible datasets
      .map((dataset) => ({
        label: dataset.label + ' (Remembered)',
        data: [...dataset.data], // Copy the current data
        fill: false,
        borderColor: dataset.borderColor, // Retain the original line color
        borderDash: [5, 5], // Dotted line style
      })),
  ]);
};


  const forgetLines = () => {
    setRememberedLines([]);
  };

  const getIconByTopic = (topic) => {
    switch (topic) {
      case 'DEMOGRAPHICS':
        return <FaUsers style={{ color: '#FF5733' }} />;
      case 'LABOR MARKET':
        return <FaBriefcase style={{ color: '#33B5E5' }} />;
      case 'ORGANIZATION AND FIRMS':
        return <MdFactory style={{ color: '#8D33FF' }} />;
      case 'CULTURE':
          return <FaMusic style={{ color: '#FF9800' }} />;
      case 'EDUCATION':
          return <FaBook style={{ color: '#4CAF50' }} />;
      case 'REAL ESTATE':
          return <FaHome style={{ color: '#FF5722' }} />; // Added real estate icon
      case 'HOUSEHOLDS':
            return <FaHouseUser style={{ color: '#607D8B' }} />; // Added households icon
        default:
        return null;
    }
  };

  return (
    <div className="chart-container">
      <div className="chart-header">
        <h3>{getIconByTopic(topic)} {title}</h3>
        <div>
{/*           <button onClick={rememberLine}>Remember Line</button>
          <button onClick={forgetLines}>Forget Lines</button> */}
          <button onClick={toggleGraph}>
            {showGraph ? 'Hide' : 'Show'}
          </button>
        </div>
      </div>
      {showGraph && (
        <Line
          data={{
            ...data,
            datasets: [...rememberedLines, ...data.datasets],
          }}
        />
      )}
    </div>
  );
};

export default GraphComponent;
