import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import logo from './assets/Logo LOS Analytics blue on grey.png';
import { FaUsers, FaBriefcase, FaMusic, FaBook, FaHome, FaHouseUser } from 'react-icons/fa';
import { MdFactory } from 'react-icons/md';
import GraphComponent from './GraphComponent';
import SliderComponent from './SliderComponent';
import './App.css';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const App = () => {
  const [graphData, setGraphData] = useState(null); // State to store graphData
  const [showGraphs, setShowGraphs] = useState([]);
  const [sliders, setSliders] = useState([]);
  const [selectedGraphs, setSelectedGraphs] = useState([]);
  const [municipality, setMunicipality] = useState('Utsira');
  const [areaOfInterest, setAreaOfInterest] = useState('basic forecast');
  const [rememberGlobal, setRememberGlobal] = useState(false);
  const [forgetGlobal, setForgetGlobal] = useState(false);

  // Fetch graphData dynamically based on municipality and areaOfInterest
  const fetchGraphData = async (municipality, areaOfInterest) => {
    try {
      const response = await fetch(
        `http://172.232.134.245/api/${municipality}/${areaOfInterest}`
      );
      if (response.ok) {
        const data = await response.json();
        setGraphData(data);
      } else {
        console.error('Failed to load graph data.');
      }
    } catch (error) {
      console.error('Error fetching graph data:', error);
    }
  };

  // Fetch graph data whenever municipality or areaOfInterest changes
  useEffect(() => {
    fetchGraphData(municipality, areaOfInterest);
  }, [municipality, areaOfInterest]);

  // Initialize sliders and show graphs when graphData is available
  useEffect(() => {
    if (graphData) {
      const initialSliders = graphData.sliders.map(slider => slider.value);
      setSliders(initialSliders);
      setShowGraphs(graphData.graphsSets[0]?.graphs.map((_, index) => index === 0) || []);
    }
  }, [graphData]);

  // Update selected graphs based on slider changes
  useEffect(() => {
    if (graphData) {
      const datasetName = graphData.sliders.map((slider, index) =>
        `${slider.label.replace(/\s+/g, '')}_${sliders[index]}`
      ).join('*');

      const matchingGraphSet = graphData.graphsSets.find(set => set.name === datasetName);

      if (matchingGraphSet) {
        setSelectedGraphs(matchingGraphSet.graphs);
      } else {
        setSelectedGraphs([]);
      }
    }
  }, [sliders, graphData]);

  const handleSliderChange = (index, value) => {
    const newSliders = [...sliders];
    newSliders[index] = value;
    setSliders(newSliders);
  };

  const toggleGraph = (index) => {
    const newShowGraphs = [...showGraphs];
    newShowGraphs[index] = !newShowGraphs[index];
    setShowGraphs(newShowGraphs);
  };

  const handleRememberScenario = () => {
    setRememberGlobal(true);
    setTimeout(() => setRememberGlobal(false), 100); // Reset the flag after a brief delay
  };

  const handleForgetScenario = () => {
    setForgetGlobal(true);
    setTimeout(() => setForgetGlobal(false), 100); // Reset the flag after a brief delay
  };

  const colorPalette = [
    'rgba(0, 123, 255, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
    'rgba(255, 99, 132, 1)',
    'rgba(255, 205, 86, 1)',
    'rgba(54, 162, 235, 1)',
  ];

  const topicIcons = [
    { icon: <FaUsers style={{ color: '#FF5733' }} />, label: 'Demographics' },
    { icon: <FaBriefcase style={{ color: '#33B5E5' }} />, label: 'Labor Market' },
    { icon: <MdFactory style={{ color: '#8D33FF' }} />, label: 'Organizations and Firms' },
    { icon: <FaMusic style={{ color: '#FF9800' }} />, label: 'Culture' },
    { icon: <FaBook style={{ color: '#4CAF50' }} />, label: 'Education' },
    { icon: <FaHome style={{ color: '#FF5722' }} />, label: 'Real Estate' }, 
    { icon: <FaHouseUser style={{ color: '#607D8B' }} />, label: 'Households' },
  ];

  return (
    <div className="app-container">
      <div className="above-card-container">
        <img src={logo} alt="LOS Analytics Logo" className="logo" />
        <div className="text-and-buttons">
          <div className="title-subtitle">
            <h2 className="title">Your Regional Digital Twin</h2>
            <h2 className="subtitle">local socio-economic simulations</h2>
          </div>
          <div className="buttons-container">
            <button>About Method</button>
            <button>Use Examples</button>
            <button>Interested? Contact Us</button>
          </div>
        </div>
      </div>
      <div className="selection-container">
        <div className="municipality-select">
          <label htmlFor="municipality">Municipality: </label>
          <select
            id="municipality"
            value={municipality}
            onChange={(e) => setMunicipality(e.target.value)}
          >
            <option value="Utsira">Utsira</option>
            <option value="Bodø">Bodø</option>
            <option value="Narvik">Narvik</option>
            <option value="Stavanger">Stavanger</option>
            <option value="Strand">Strand</option>
          </select>
        </div>
        <div className="area-select">
          <label htmlFor="areaOfInterest">Your Area of Interest: </label>
          <select
            id="areaOfInterest"
            value={areaOfInterest}
            onChange={(e) => setAreaOfInterest(e.target.value)}
          >
            <option value="basic forecast">Basic Forecast</option>
            <option value="labor market">Labor Market</option>
            <option value="real estate">Real Estate</option>
            <option value="culture">Culture</option>
            <option value="large-scale projects">Large-scale Projects</option>
            <option value="public sector planning">Public Sector Planning</option>
          </select>
        </div>
      </div>
      <div className="card">
        <div className="charts-container">
          {selectedGraphs.length > 0 ? selectedGraphs.map((graph, index) => (
            <GraphComponent
              key={index}
              data={{
                labels: graph.labels,
                datasets: graph.data.map((serie, serieIndex) => ({
                  label: serie.serieLabel,
                  data: serie.serie,
                  fill: false,
                  borderColor: colorPalette[serieIndex % colorPalette.length],
                  tension: 0.4,
                })),
              }}
              title={graph.title}
              topic={graph.topic}
              showGraph={showGraphs[index]}
              toggleGraph={() => toggleGraph(index)}
              rememberGlobal={rememberGlobal}
              forgetGlobal={forgetGlobal}
            />
          )) : <p>No graphs available for the current slider configuration.</p>}
        </div>
        <div className="sliders-container">
          <div className="legend-container">
            {topicIcons.map((topic, index) => (
              <div key={index} className="legend-item">
                {topic.icon}
                <span className="legend-label">{topic.label}</span>
              </div>
            ))}
          </div>
          {graphData && graphData.sliders.map((slider, index) => (
            <SliderComponent
              key={index}
              label={slider.label}
              min={slider.min}
              max={slider.max}
              step={slider.step}
              value={sliders[index]}
              onChange={(value) => handleSliderChange(index, value)}
              info={slider.info}
              scale={slider.scale}
            />
          ))}
          <button onClick={handleRememberScenario}>Remember Scenario</button>
          <button onClick={handleForgetScenario}>Forget Scenario</button>
          <div className="map-container">
            <MapContainer center={[68.4385, 17.4279]} zoom={13} scrollWheelZoom={false} style={{ height: "400px", width: "100%" }}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker position={[68.4385, 17.4279]}></Marker>
            </MapContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
