import React, { useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';

const SliderComponent = ({ label, min, max, step, value, onChange, info, scale }) => {
  const [showInfo, setShowInfo] = useState(false);

  const handleInfoClick = () => {
    setShowInfo(true);
  };

  const handleCloseClick = () => {
    setShowInfo(false);
  };

  return (
    <div className="slider-container">
      <div className="slider-label">
        <label>{label}: {value}</label>
        <FaInfoCircle className="info-icon" onClick={handleInfoClick} />
      </div>
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={(e) => onChange(Number(e.target.value))}
      />
      {scale.length > 0 && (
        <div className="slider-scale">
          {scale.map((scaleValue, index) => (
            <span key={index} className="slider-scale-value">
              {scaleValue}
            </span>
          ))}
        </div>
      )}
      {showInfo && (
        <div className="info-popup">
          <div className="info-content">
            <p>{info}</p>
            <button onClick={handleCloseClick}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SliderComponent;
